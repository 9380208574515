









/**
 * Created by wangtong on 2022/2/7
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import ShareCard from "@/components/ShareCard.vue";
import BasePage from "@/mixins/BasePage";
import MobileAppDownloader from "@/components/app_downloader/MobileAppDownloader.vue";
import PcAppDownloader from "@/components/app_downloader/PcAppDownloader.vue";
import {createUserShareData, UserShareData} from "@/models/user";
import {UserApi} from "@/api/user";
import UserShareCard from "@/components/share/UserShareCard.vue";

@Component({
    components: {UserShareCard, PcAppDownloader, MobileAppDownloader, ShareCard}
})
export default class UserSharePage extends BasePage {
    @Prop() id!: any

    shareData: UserShareData = createUserShareData()

    async created() {
        super.created()
        this.shareData = await UserApi.getShareData({share_id: this.id})
    }

    get appSchema(): string {
        return `sandan:///app/user/profile?userId=${this.shareData.record.target_user_id}`
    }
}
