/**
 * Created by wangtong on 2022/2/7
 */


import {ApiV1Service, RequestConfig, Post, Get} from "@/api/base";
import * as models from "@/models"

class UserService extends ApiV1Service {
	@Get('/user/share_data')
	async getShareData(payload: models.GetUserShareDataRequest, config?: RequestConfig): Promise<models.UserShareData> {
		return this.request(payload, config)
	}
}

export const UserApi = new UserService()

